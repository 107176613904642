exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-articles-de-js": () => import("./../../../src/pages/blog/articles.de.js" /* webpackChunkName: "component---src-pages-blog-articles-de-js" */),
  "component---src-pages-blog-articles-en-js": () => import("./../../../src/pages/blog/articles.en.js" /* webpackChunkName: "component---src-pages-blog-articles-en-js" */),
  "component---src-pages-blog-de-js": () => import("./../../../src/pages/blog.de.js" /* webpackChunkName: "component---src-pages-blog-de-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-help-de-js": () => import("./../../../src/pages/help.de.js" /* webpackChunkName: "component---src-pages-help-de-js" */),
  "component---src-pages-help-en-js": () => import("./../../../src/pages/help.en.js" /* webpackChunkName: "component---src-pages-help-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-de-js": () => import("./../../../src/pages/knowledge.de.js" /* webpackChunkName: "component---src-pages-knowledge-de-js" */),
  "component---src-pages-knowledge-en-js": () => import("./../../../src/pages/knowledge.en.js" /* webpackChunkName: "component---src-pages-knowledge-en-js" */),
  "component---src-pages-pricing-de-js": () => import("./../../../src/pages/pricing.de.js" /* webpackChunkName: "component---src-pages-pricing-de-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-tools-sweat-rate-calculator-de-js": () => import("./../../../src/pages/tools/sweat-rate-calculator.de.js" /* webpackChunkName: "component---src-pages-tools-sweat-rate-calculator-de-js" */),
  "component---src-pages-tools-sweat-rate-calculator-en-js": () => import("./../../../src/pages/tools/sweat-rate-calculator.en.js" /* webpackChunkName: "component---src-pages-tools-sweat-rate-calculator-en-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/defaultTemplate.js" /* webpackChunkName: "component---src-templates-default-template-js" */)
}

